import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import CalendarWeekdays from './calendar_weekdays'
import CalendarMonth from './calendar_month'
import {classNames} from '../utils/classnames'

class Calendar extends React.Component {
  static propTypes = {
    width:                    PropTypes.any,
    weekdays_alignment:       PropTypes.string,
    day_alignment:            PropTypes.string,
    previous_arrow:           PropTypes.string,
    next_arrow:               PropTypes.string,
    events:                   PropTypes.array,
    init_date:                PropTypes.string
  }

  static defaultProps = {
    width: '100%',
    weekdays_alignment: 'center',
    day_alignment: 'right',
    previous_label: '&#9664;',
    next_label: '&#9654;',
    event_colors: [
      '#1678c2',
      '#16ab39',
      '#fbbd08',
      '#2185d0',
      '#6435c9',
    ]
  }

  state = {
    month: this.props.init_date ? moment(this.props.init_date) : moment(),
    events: this.props.events ? this.props.events : [],
    loading: false
  }

  componentDidMount() {
    localStorage.clear();
  }

  resetClick(e) {
    this.fetchEvents(moment());
    this.setState({
      month: moment()
    })
  }

  previousClick(e) {
    var new_month = this.state.month.clone().subtract(1, 'M');
    this.fetchEvents(new_month);
    this.setState({
      month: new_month
    })
  }

  nextClick(e) {
    var new_month = this.state.month.clone().add(1, 'M');
    this.fetchEvents(new_month);
    this.setState({
      month: new_month
    })
  }

  fetchEvents(start_date) {
    if (!start_date.isValid()) {
      start_date = moment();
    }
    var new_url = window.location.href.replace(
      /year\/\d{4}\/month\/\d+/,
      'year/' + start_date.format('YYYY') + '/month/' + start_date.format('MM')
    );

    if (localStorage.getItem(start_date.format('YYYY-MM'))) {
      this.setState({
        events: JSON.parse(localStorage.getItem(start_date.format('YYYY-MM')))
      });
      return;
    }

    this.setState({
      loading: true
    });

    $.ajax({
      url: new_url + '.json',
      dataType: 'json',
      success: function(data) {
        var entry_date = start_date.format('YYYY-MM');
        localStorage.setItem(entry_date, JSON.stringify(data));
        window.history.pushState('', 'Calendar (' + start_date.format('MMMM YYYY') + ')', new_url);
        this.setState({
          events: data,
          loading: false
        });
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
        this.setState({
          events: []
        });
      }.bind(this)
    });
  }

  render() {
    var segment_classes = classNames('calendar', {loading: this.state.loading});

    return (
      <div className={segment_classes} style={{"width" : this.props.width}}>
        <div className='top'>
          <div
            className='previous'
            onClick={this.previousClick.bind(this)}
            dangerouslySetInnerHTML={{__html: this.props.previous_label}}>
            </div>
          <div className='reset' onClick={this.resetClick.bind(this)}>Today</div>
          <div
            className='next'
            onClick={this.nextClick.bind(this)}
            dangerouslySetInnerHTML={{__html: this.props.next_label}} />
          <div className='label'>
            <span className='medium month'>
              {this.state.month.format('MMMM')}
            </span>
            <span className='small month'>
              {this.state.month.format('MMM')}
            </span>
            <span className='year'>
              {this.state.month.format('YYYY')}
            </span>
          </div>

        </div>
        <CalendarWeekdays
          day_alignment={this.props.weekdays_alignment} />
        <CalendarMonth
          month={this.state.month}
          day_alignment={this.props.day_alignment}
          events={this.state.events}
          colors={this.props.event_colors} />
      </div>
    )
  }
}

export default Calendar;
