import React from "react"

class SuiIcon extends React.Component {
  render() {
    return (
      <i className={'icon ' + this.props.settings}></i>
    )
  }
}

export default SuiIcon;
