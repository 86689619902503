import React from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

class PtoComments extends React.Component {
  static propTypes = {
    comments:                   PropTypes.array,
    current_user_id:            PropTypes.number
  }

  state = {
    comments: this.props.comments,
    new_comment: '',
    reply_loading: false,
    disabled: true,
    notify_comment: true,
    comment_loading: false
  }

  updateNotification = (e) => {
    this.setState({
      notify_comment: e.target.checked
    });
  }

  postNewComment = (e) => {
    e.preventDefault();
    this.setState({
      reply_loading: true
    });

    var data = {};
    data.pto_comment = {};
    data.pto_comment.pto_request_id = this.props.pto_request_id;
    data.pto_comment.note = this.state.new_comment;
    data.pto_comment.notify_comment = this.state.notify_comment ? 1 : 0;

    $.ajax({
      url: '/pto_comments.json',
      method: 'POST',
      data: data,
      dataType: 'json',
      success: function(data) {
        this.setState({
          new_comment: '',
          comments: update(this.state.comments, {$push: [data]}),
          reply_loading: false
        });
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
      }.bind(this)
    });
  }

  deleteComment = (index, e) => {
    e.preventDefault();
    var comment = this.state.comments[index];
    this.setState({
      comment_loading: comment.id
    });

    $.ajax({
      url: '/pto_comments/' + comment.id + '.json',
      method: 'DELETE',
      dataType: 'json',
      success: function(data) {
        this.setState({
          comments: update(this.state.comments, {$splice: [[index, 1]]}),
          comment_loading: false
        })
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
      }.bind(this)
    });
  }

  updateNewComment = (e) => {
    this.setState({
      new_comment: e.target.value,
      disabled: e.target.value === '' ? true : false
    })
  }

  render() {
    var current_user_id = this.props.current_user_id;

    return (
      <div className="ui comments">
        <h3 className="ui dividing header">Comments</h3>
          {this.state.comments.map(function(comment, index) {
            return <PtoComment
                      deleteComment={this.deleteComment.bind(null, index)}
                      key={comment.id}
                      comment={comment}
                      loading={this.state.comment_loading}
                      current_user_id={current_user_id} />;
          }.bind(this))}
        <form className={(this.state.reply_loading ? 'loading' : '') + " ui reply form"}>
          <div className="field">
            <textarea
              value={this.state.new_comment}
              onChange={this.updateNewComment}>
            </textarea>
          </div>
          <div className="field">
            <div className="ui checkbox">
              <input
                onChange={this.updateNotification}
                checked={this.state.notify_comment}
                id="notify_comment"
                type="checkbox" />
              <label htmlFor="notify_comment">Email others about my comment</label>
            </div>
          </div>
          <div
            onClick={this.postNewComment}
            className={(this.state.disabled ? 'disabled' : '') + " ui blue labeled submit icon button"}>
            <i className="icon edit"></i> Add Comment
          </div>
        </form>
      </div>
    )
  }
}

export default PtoComments;
