import React from "react"
import PropTypes from "prop-types"
import Pagination from '../shared/pagination'
import SuiIcon from '../semantic_ui/semantic'
import * as formatters from '../utils/formatters'
import {classNames} from '../utils/classnames'

class PtoEventsTable extends React.Component {
  static propTypes = {
    events:                   PropTypes.array,
    pto_balance:              PropTypes.number,
    unlimited:                PropTypes.bool
  }

  state = {
    current_page: 1
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.events != this.props.events) {
      this.setState({
        current_page: 1
      });
    }
  }

  setCurrentPage = (current_page) => {
    this.setState({current_page: current_page});
  }

  render() {
    if (!this.props.events.length) {
      return <div className='ui basic segment'>
        Nothing to report yet.
      </div>;
    }

    var current_page = this.state.current_page;
    var items_per_page = 9;
    var page_count = Math.ceil(this.props.events.length / items_per_page)

    var events = [];
    for (var i=(current_page - 1) * items_per_page; i < (current_page) * items_per_page; i++) {
      var item = this.props.events[i];
      if (item === undefined) {
        continue;
      }

      var pto_used = item.pto_amount < 0 ? formatters.formatDays(item.pto_amount) : ''
      var pto_gained = item.pto_amount > 0 ? formatters.formatDays(item.pto_amount) : ''

      var classes = classNames(
        {positive: item.pto_amount > 0},
        {negative: item.pto_amount < 0},
        {first: i == 0}
      );
      var type_icon = item.pto_amount > 0 ? 'add circle' : 'calendar';

      if (i == 0) {
        var balance = <div className='ui statistic small'>
                    <div className='value'>
                      {formatters.truncateDays(item.pto_balance)}
                    </div>
                    <div className='label'>
                      days of PTO
                    </div>
                  </div>
      }
      else {
        var balance = <div>
                    <span>{formatters.formatDays(item.pto_balance)}</span>
                    <span className='mobile-only'> of available PTO</span>
                  </div>
      }
      events.push(
        <tr className={classes} key={i}>
          <td>{item.pto_date}</td>
          <td>
            <span className='mobile-only'>
              <SuiIcon settings={type_icon} />
            </span>{item.pto_type}
          </td>
          <td>
            {item.dates ? <p><a href={item.url}>{item.dates}</a></p> : ''}
            {item.comments ? <SuiIcon settings='comment outline' /> : ''}
            {item.url ? <a href={item.url}>{item.pto_note}</a> : item.pto_note}
          </td>
          <td className='right aligned'>{pto_used}</td>
          <td className='pto_gained right aligned'>{pto_gained}</td>
          <td className='balance right aligned'>{balance}</td>
        </tr>
      );
    };

    return (
      <div className='pto_events'>
        <Pagination
          setCurrentPage={this.setCurrentPage}
          current_page={current_page}
          page_count={page_count}
          compact={true} />
        <table className='ui table'>
          <thead className='desktop-only'>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Note</th>
              <th className='right aligned'>{this.props.unlimited ? '' : 'Used'}</th>
              <th className='pto_gained right aligned'>{this.props.unlimited ? 'Used' : 'Gained' }</th>
              <th className='balance right aligned'>Balance</th>
            </tr>
          </thead>
          <tbody>
            {events}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PtoEventsTable;
