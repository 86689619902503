import React from "react"
import PropTypes from "prop-types"
import PtoRequestRows from './pto_requests_rows.js.jsx'

class PtoRequestsTable extends React.Component {
  static propTypes = {
    pto_requests:           PropTypes.array.isRequired
  }

  render() {
    if (!this.props.pto_requests.length) {
      return (
        <div><br/><p>No PTO requests made so far.</p></div>
      )
    }
    return (
      <div>
        <p>
          <table className='ui table'>
            <thead className='desktop-only'>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Note</th>
                <th className='right aligned'>Amount</th>
                <th className='right aligned'>Submitted</th>
                <th></th>
              </tr>
            </thead>
            <PtoRequestRows pto_requests={this.props.pto_requests} />
          </table>
        </p>
      </div>
    )
  }
}

export default PtoRequestsTable;
