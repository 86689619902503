import React from "react"
import PropTypes from "prop-types"
import {classNames} from '../utils/classnames'

class Pagination extends React.Component {
  static propTypes = {
    current_page:           PropTypes.number,
    page_count:             PropTypes.number.isRequired,
    setCurrentPage:         PropTypes.func.isRequired,
    compact:                PropTypes.bool,
    range:                  PropTypes.number
  }

  static defaultProps = {
    current_page:           1,
    compact:                false,
    range:                  3
  }

  render() {
    // Do not render pagination if there's a single page
    if (this.props.page_count === 1) {
      return null;
    }

    var pager = [];

    // First
    if (this.props.current_page >= this.props.range + 1) {
      pager.push(
        <a key={-1} onClick={this.props.setCurrentPage.bind(null, 1)} className='item'>
          <i className='double angle left icon' /> First
        </a>
      );
    }

    // Previous page
    if (this.props.current_page != 1) {
      pager.push(
        <a key={0} onClick={this.props.setCurrentPage.bind(null, this.props.current_page - 1)} className='icon item'>
          <i className="angle left icon"></i> Previous
        </a>
      );
    }

    // Middle
    for (var i = 1; i <= this.props.page_count; i++) {
      var pager_class = classNames('item', {active: this.props.current_page == i});
      if (this.props.compact && (i < this.props.current_page - 2) || (i > this.props.current_page + 2)) {
        continue;
      }
      else {
        pager.push(
          <a key={i} className={pager_class} onClick={this.props.setCurrentPage.bind(null, i)}>{i}</a>
        );
      }
    }

    // Next page
    if (this.props.current_page != this.props.page_count) {
      pager.push(
        <a key={this.props.page_count + 1} onClick={this.props.setCurrentPage.bind(null, this.props.current_page + 1)} className='icon item'>
          Next <i className="angle right icon" />
        </a>
      );
    }

    // Last
    if (this.props.current_page <= this.props.page_count - this.props.range) {
      pager.push(
        <a key={this.props.page_count + 2} onClick={this.props.setCurrentPage.bind(null, this.props.page_count)} className='item'>
          Last <i className='double angle right icon' />
        </a>
      );
    }

    return(
      <div className='ui borderless pagination menu'>
        {pager}
      </div>
    );
  }
}

export default Pagination;
