import React from "react"
import PropTypes from "prop-types"

class PtoRequestRows extends React.Component {
  static propTypes = {
    pto_requests:           PropTypes.array.isRequired
  }

  render() {
    var createRow = function(item) {
      display_date = item.start_date == item.end_date ? item.start_date : item.start_date + ' - ' + item.end_date
      return (
        <tr key={item.id}>
          <td>{display_date}</td>
          <td>{item.pto_type}</td>
          <td>{item.note}</td>
          <td className='right aligned'>{item.amount} days</td>
          <td className='right aligned'><span className='mobile-only'>Requested on </span>{item.created_at}</td>
          <td><a href={'/pto_requests/' + item.id + '/edit'}><i className='ui edit icon' /></a></td>
        </tr>
      )
    };
    return <tbody>{this.props.pto_requests.map(createRow)}</tbody>
  }
}

export default PtoRequestRows;
