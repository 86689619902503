import React from 'react'
import PropTypes from 'prop-types'
import AccrualList from './accrual_list'
import AddButton from '../shared/add_button'
import update from 'immutability-helper'

class AccrualRangesApp extends React.Component {
  static propTypes = {
    items:           PropTypes.string.isRequired,
    field_name:      PropTypes.string.isRequired,
    width:           PropTypes.string
  }

  constructor(props) {
    super(props);
    this.state = {
      items: JSON.parse(props.items) || [{amount: ''}]
    };
  }

  componentDidMount() {
    $('.clickable-popup').popup({on: 'click'})
  }

  handleAddClick = (e) => {
    e.preventDefault();
    var nextItems = this.state.items.concat([{amount: ''}]);
    this.setState({
      items: nextItems
    });
  }

  handleRemoveClick = (item) => {
    this.state.items.splice(item, 1);
    this.setState({
      items: this.state.items
    });
  }

  handleAmountChange = (i, e) => {
    // var update = {};
    // update[i] = {};
    // update[i]['amount'] = {$set: e.target.value};
    // var items = update(this.state.items, update);

    // ES6 magic will work eventually...
    var items = update(this.state.items, {
      [i]: {
        amount: {$set: e.target.value}
      }
    });
    this.setState({
      items: items
    });
  }

  render() {
    return (
      <div className='field'>
        <i
          data-content="Set how much time off is earned during each year following the employee's hire date. The last entry will be used for anything beyond it."
          data-title="Yearly Accrual"
          className="large help blue clickable-popup circle icon" />
        <label>PTO Accrual ranges</label>
        <AccrualList
          items={this.state.items}
          field_name={this.props.field_name}
          width={this.props.width}
          handleRemoveClick={this.handleRemoveClick}
          handleAmountChange={this.handleAmountChange} />
        <AddButton
          handleClick={this.handleAddClick} />
      </div>
    )
  }
}

export default AccrualRangesApp;
