import React from "react"
import PropTypes from "prop-types"

class YearDropdown extends React.Component {
  static propTypes = {
    options:            PropTypes.array,
    handleChange:       PropTypes.func,
    value:              PropTypes.string
  }

  componentDidMount() {
    $('.year.dropdown').dropdown();
  }

  render() {
    var createOption = function(option, index) {
      return <option key={index + 1} value={option}>{option}</option>
    };

    if (this.props.options.length <= 1) {
      return null;
    }

    return (
      <select
        value={this.props.value}
        className='ui compact year dropdown'
        onChange={this.props.handleChange}>
          <option key='0' value='0'>All Years</option>
          {this.props.options.map(createOption)}
      </select>
    )
  }
}

export default YearDropdown;
