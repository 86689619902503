import React from 'react'
import PropTypes from 'prop-types'

class ChartLegend extends React.Component {
  static propTypes = {
    color:            PropTypes.string.isRequired,
    text:             PropTypes.string.isRequired
  }

  render() {
    return (
      <svg width="250" height="45">
        <rect x='0' y='10' width="25" height="25" fill={this.props.color} />
        <text x='35' y='27' >{this.props.text}</text>
      </svg>
    )
  }
}

export default ChartLegend;
