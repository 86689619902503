import React from "react"
import PropTypes from "prop-types"

class AddButton extends React.Component {
  static propTypes = {
    id:           PropTypes.string,
    handleClick:  PropTypes.func.isRequired
  }

  render() {
    return (
      <div id={this.props.id} className="ui teal icon button" onClick={this.props.handleClick}>
        <i className="plus icon" />Add
      </div>
    )
  }
}

export default AddButton;
