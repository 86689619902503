import React from "react"
import PropTypes from "prop-types"

class YearlyPtoUsage extends React.Component {
  static propTypes = {
    years:            PropTypes.array,
    pto_earned:       PropTypes.array,
    pto_used:         PropTypes.array,
    pto_unused:       PropTypes.array
  }

  constructor(props) {
    super(props);
    var data = JSON.parse(props.data);

    this.state = {
      years: data.years,
      pto_used_per_year: data.pto_used_per_year,
      pto_earned_per_year: data.pto_earned_per_year,
      pto_unused_per_year: data.pto_unused_per_year
    }
  }

  componentDidMount() {
    new Chartist.Bar('.ct-chart', {
      labels: this.state.years,
      series: [
        {
          name: 'days of earned PTO',
          data: this.state.pto_earned_per_year,
        },
        {
          name: 'days of used PTO',
          data: this.state.pto_used_per_year
        },
        {
          name: 'days of unused PTO',
          data: this.state.pto_unused_per_year
        }
      ]
    }, {
      seriesBarDistance: 30,
      plugins: [
        ctBarLabels({
          labelClass: 'ct-bar-label',
          textAnchor: 'middle'
        })
      ]
    },
    [
      ['screen and (max-width: 640px)', {
        seriesBarDistance: 10
      }]
    ]
    );

    var $chart = $('.ct-chart');

    $chart.on('mouseenter', '.ct-bar', function() {
      var $bar = $(this),
        value = $bar.attr('ct:value'),
        seriesName = $bar.parent().attr('ct:series-name');

      $bar
        .popup({
          content: value + ' ' + seriesName
        });
      $bar.popup('show');
    });
  }

  render() {
    return (
      <div>
        <div className="ct-chart ct-major-tenth"></div>
        <ChartLegend
          color='#3b83c0'
          text='PTO earned (days)' />
        <ChartLegend
          color='#5bbd72'
          text='PTO used (days)' />
        <ChartLegend
        color='#e07b53'
        text='PTO unused (days)' />
      </div>
    )
  }
}

export default YearlyPtoUsage;
