import React from 'react'
import PropTypes from 'prop-types'
import PolicyAccrualDaysFields from './policy_accrual_days_fields'
import AddButton from '../shared/add_button'

class PolicyAccrualDaysApp extends React.Component {
  static propTypes = {
    accrual_days:           PropTypes.string,
    field_name:             PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      accrual_days: JSON.parse(props.accrual_days) || ['']
    };
  }

  componentDidMount() {
    $.fn.form.settings.rules.accrual_day_required_for_limited_policy = function(value) {
      var unlimited = document.getElementById('policy_unlimited').checked;
      if (unlimited) {
        return true;
      }
      return _.isInteger(Number(value));
    }

    $('.ui.form.policy').form({
      fields: {
        company_id: {
          identifier  : 'policy_company_id',
          rules: [
            {
              type   : 'empty',
              prompt : 'Please select a company'
            }
          ]
        },
        name: {
          identifier  : 'policy_name',
          rules: [
            {
              type   : 'empty',
              prompt : 'Please enter a name'
            }
          ]
        },
        accrual_day: {
          identifier  : 'policy_accrual_days',
          rules: [
            {
              type   : 'accrual_day_required_for_limited_policy',
              prompt : 'Please enter at least one accrual day between 1 and 28'
            }
          ]
        }
      }
    });
  }

  handleAddClick = (e) => {
    e.preventDefault();
    var accrual_days = this.state.accrual_days.concat(['']);
    this.setState({
      accrual_days: accrual_days
    });
  }

  handleRemoveClick = (i) => {
    var accrual_days = this.state.accrual_days.slice();
    accrual_days.splice(i, 1);
    this.setState({
      accrual_days: accrual_days
    });
  }

  onDayChange = (i, e) => {
    var accrual_days = this.state.accrual_days.slice();
    accrual_days[i] = e.target.value;
    this.setState({
      accrual_days: accrual_days
    });
  }

  render() {
    return (
      <div className='required field'>
        <i
          data-content="Day(s) between the 1st and the 28th of the month when PTO is accrued. You need at least one value."
          data-title=""
          className="large help blue clickable-popup circle icon" />
        <label>Accrual days</label>
        <PolicyAccrualDaysFields
          accrual_days={this.state.accrual_days}
          field_name={this.props.field_name}
          RemoveClick={this.handleRemoveClick}
          onDayChange={this.onDayChange} />
        {this.state.accrual_days.length < 4 ?
          <AddButton
            handleClick={this.handleAddClick} /> : null}
      </div>
    )
  }
}

export default PolicyAccrualDaysApp;
