import React from 'react'
import PropTypes from 'prop-types'
import DeleteButton from '../shared/delete_button'
import Dropdown from './dropdown'

class CompanyPolicyApp extends React.Component {
  static propTypes = {
    company_field_id:         PropTypes.string,
    company_field_name:       PropTypes.string.isRequired,
    company_id:               PropTypes.any,
    company_options:          PropTypes.string,
    disable_company_dropdown: PropTypes.bool,
    policies_url:             PropTypes.string.isRequired,
    policy_field_id:          PropTypes.string.isRequired,
    policy_field_name:        PropTypes.string.isRequired,
    policy_id:                PropTypes.any,
    policy_options:           PropTypes.any
  }

  constructor(props) {
    super(props);
    this.state = {
      policy_options: props.policy_options
    }
  }

  resetPolicies() {
    var policy_dropdown = $('#' + this.props.policy_field_id).parent();
    policy_dropdown.dropdown('clear');
    policy_dropdown.addClass('disabled');
  }

  updatePolicies = (val) => {
    var policy_dropdown = $('#' + this.props.policy_field_id).parent();
    policy_dropdown.removeClass('disabled');

    $.ajax({
      url: this.props.policies_url.replace('ID', val),
      dataType: 'json',
      success: function(data) {
        this.setState({
          policy_options: data,
          errors: []
        });
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.policies_url, status, err.toString());
      }.bind(this)
    });
  }

  render() {
    return (
      <div className='fields'>
        <div className='six wide field'>
          <Dropdown
            field_id={this.props.company_field_id}
            field_name={this.props.company_field_name}
            field_label='Company'
            options={this.props.company_options}
            selected_option={this.props.company_id}
            changeCallback={this.updatePolicies}
            disabled={this.props.disable_company_dropdown} />
        </div>
        { this.props.disable_company_dropdown ? <div className="two wide field"></div> :
          (
            <div className="two wide field">
              <label className='desktop-only'>&nbsp;</label>
              <DeleteButton handleClick={this.resetPolicies} id={'clear_' + this.props.company_field_id} />
            </div>
          )
        }
        <div className='six wide field'>
          <Dropdown
            field_id={this.props.policy_field_id}
            field_name={this.props.policy_field_name}
            field_label='Policy'
            options={this.state.policy_options}
            selected_option={this.props.policy_id} />
        </div>
        <div className="two wide field">
          <label className='desktop-only'>&nbsp;</label>
          <DeleteButton id={'clear_' + this.props.policy_field_id} />
        </div>
      </div>
    )
  }
}

export default CompanyPolicyApp;
