import React from "react"
import PropTypes from "prop-types"

class ErrorMessages extends React.Component {
  static propTypes = {
    errors:           PropTypes.array
  }

  render() {
    var errorList = function(error, i) {
      return (
        <li key={i}>{error}</li>
      );
    };
    if (!this.props.errors.length) {
      return <div />
    }
    if (this.props.errors.length === 1) {
      return (
        <div className='ui visible error message'>
          {this.props.errors[0]}
        </div>
      );
    }
    return (
      <div className='ui visible error message'>
        <ul className='list'>
          {this.props.errors.map(errorList)}
        </ul>
      </div>
    );
  }
}

export default ErrorMessages;
