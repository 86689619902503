import React from 'react'
import PropTypes from "prop-types"
import PtoEventsTable from './pto_events_table'
import YearDropdown from './year_dropdown'
import ErrorMessages from '../shared/error_messages'
import moment from 'moment'
import {classNames} from '../utils/classnames'

class PtoHistoryApp extends React.Component {
  static propTypes = {
    data:                   PropTypes.string.isRequired,
    pto_balance:            PropTypes.number.isRequired,
    url:                    PropTypes.string.isRequired,
    unlimited:              PropTypes.bool
  }

  constructor(props) {
    super(props);
    var data = JSON.parse(this.props.data);
    this.state = {
      end_date: '',
      events: data.events,
      pto_balance: this.props.pto_balance,
      date_invalid: false,
      errors: [],
      years: data.years,
      disable_button: true,
      filter_year: ''
    };
  }

  componentDidMount() {
    var datefield = document.createElement("input")
    datefield.setAttribute("type", "date")
    if (datefield.type != "date") {
      var datefield_supported = false;
    }
    else {
      var datefield_supported = true;
    }

    if (!datefield_supported) {
      $('.datepicker').datepicker({
        dateFormat: "yy-mm-dd",
        changeYear: true,
        changeMonth: true,
        autoclose: true,
        maxDate: '+4y',
        minDate: 0
      }).on("change", function(e) {
        this.onChange(e);
      }.bind(this));
    }
    $('.datepicker').val('');
  }

  onChange = (e) => {
    var date_invalid = false;
    var errors = [];
    var disable_button = false;

    var end_date = moment(e.target.value, 'YYYY-MM-DD');
    var max_date = moment().add(4, 'years');
    var today = moment().startOf('day');

    if (!end_date.isValid()) {
      errors.push('Invalid date entered.');
      date_invalid = true;
    }
    if (end_date.isValid() && end_date > max_date) {
      errors.push('The maximum date must be before ' + max_date.format('MM/DD/YYYY') + '.');
      date_invalid = true;
    }
    if (end_date.isValid() && today.isAfter(end_date)) {
      errors.push('The minimum date must be after  ' + today.format('MM/DD/YYYY') + '.');
      date_invalid = true;
    }
    this.setState({
      end_date: e.target.value,
      date_invalid: date_invalid,
      errors: errors,
      disable_button: date_invalid
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    var end_date = moment(this.state.end_date, 'YYYY-MM-DD');
    if (!end_date.isValid()) {
      return;
    }
    end_date = end_date.format('YYYY-MM-DD');
    this.setState({
      loading: true
    });

    $.ajax({
      url: this.props.url + '/' + end_date + '.json',
      dataType: 'json',
      success: function(data) {
        this.setState({
          events: data.events,
          pto_balance: data.pto_balance,
          end_date: end_date,
          errors: [],
          years: data.years,
          filter_year: '',
          loading: false
        });
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
      }.bind(this)
    });
  }

  filterByYear = (events) => {
    if (!this.state.filter_year) {
      return events;
    }
    else {
      var _this = this;
      return _.filter(events, function(event) {
        return event.pto_date.substr(event.pto_date.length - 4) == _this.state.filter_year;
      })
    }
  }

  filterAccrual = (events) => {
    if (this.state.hide_accrual) {
      return _.filter(events, function(event) {
        return event.pto_type != 'Accrual';
      })
    }
    else {
      return events;
    }
  }

  handleYearChange = (e) => {
    this.setState({
      filter_year: e.target.value == 0 ? '' : e.target.value
    })
  }

  hideAccrual = (e) => {
    this.setState({
      hide_accrual: !this.state.hide_accrual
    })
  }

  render() {
    var filtered_events = this.filterByYear(this.state.events);
    filtered_events = this.filterAccrual(filtered_events);

    var segment_classes = classNames('ui basic segment', {loading: this.state.loading})
    var date_classes = classNames('four wide field', {error: this.state.date_invalid});
    var button_class = classNames({disabled: this.state.disable_button});

    return (
      <div className={segment_classes} id="pto_history">
        <div className='ui form'>
          <div className='fields'>

            {this.props.unlimited ? null :
              (
                <div className={date_classes}>
                  <div className='ui left icon input'>
                    <input
                      id='pto_calculator_date'
                      type='date'
                      className='datepicker'
                      placeholder="yyyy-mm-dd"
                      onChange={this.onChange}
                      value={this.state.end_date} />
                    <i className='icon calendar' />
                  </div>
                </div>
              )
            }

            {this.props.unlimited ? null :
              (
                <div className='seven wide field'>
                  {this.state.errors.length ? null : <a
                                            id='calculate_pto'
                                            onClick={this.handleSubmit}
                                            className={'ui button green ' + button_class}>
                                            <i className='icon calculator' />
                                            Calculate PTO balance
                                        </a>}
                  <ErrorMessages errors={this.state.errors} />
                </div>
              )
            }

            {this.props.unlimited ? null :
              (
                <div className='three wide field'>
                  <div className="ui checkbox">
                    <input
                      type="checkbox"
                      name="hide_accrual"
                      id='hide_accrual_checkbox'
                      checked={this.state.hide_accrual || false}
                      onChange={this.hideAccrual} />
                    <label htmlFor='hide_accrual_checkbox'>Hide PTO Accrual</label>
                  </div>
                </div>
              )
            }

            <div className='two wide field'>
              <YearDropdown
                value={this.state.filter_year}
                options={this.state.years}
                handleChange={this.handleYearChange} />
            </div>
          </div>
        </div>
        <PtoEventsTable
          events={filtered_events}
          pto_balance={this.state.pto_balance}
          years={this.state.years}
          unlimited={this.props.unlimited} />
      </div>
    );
  }
}

export default PtoHistoryApp;
