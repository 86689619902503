import React from 'react'
import PropTypes from 'prop-types'

class DeleteButton extends React.Component {
  static propTypes = {
    id:           PropTypes.string,
    handleClick:  PropTypes.func
  }

  render() {
    return (
      <div id={this.props.id} className="ui red icon button" onClick={this.props.handleClick}>
        <i className="trash icon" />
      </div>
    )
  }
}

export default DeleteButton;
