import React from 'react'
import PropTypes from "prop-types"
import moment from 'moment'
import * as formatters from '../utils/formatters'
import {classNames} from '../utils/classnames'
import UserDropdown from './user_dropdown'
import DatepickerField from './datepicker_field'
import ErrorMessages from '../shared/error_messages'

class PtoRequestApp extends React.Component {
  static propTypes = {
    pto_balance:    PropTypes.number,
    pto_details:    PropTypes.array,
    start_date:     PropTypes.string,
    end_date:       PropTypes.string,
    balance_date:   PropTypes.string.isRequired,
    errors:         PropTypes.array,
    selected_user:  PropTypes.number.isRequired,
    url:            PropTypes.string.isRequired,
    is_new:         PropTypes.bool.isRequired,
    pto_unlimited:  PropTypes.bool.isRequired
  }

  state = {
    pto_balance: this.props.pto_balance,
    pto_details: this.props.pto_details === null ? [] : this.props.pto_details,
    start_date: this.props.start_date || '',
    end_date: this.props.end_date || '',
    balance_date: this.props.balance_date,
    errors: [],
    start_day_invalid: false,
    end_day_invalid: false,
    save_disabled: this.props.start_date == null ? true : false,
    selected_user: this.props.selected_user,
    url: this.props.url,
    pto_unlimited: this.props.pto_unlimited
  }

  componentDidMount() {
    $('.ui.form.pto_request').form({
      fields: {
        start_date: {
          identifier  : 'start_date',
          rules: [
            {
              type   : 'empty',
              prompt : 'Please select a start date'
            }
          ]
        },
        end_date: {
          identifier  : 'end_date',
          rules: [
            {
              type   : 'empty',
              prompt : 'Please select an end date'
            }
          ]
        }
      }
    });
    $('#start_date, #end_date').datepicker({
      dateFormat: "yy-mm-dd",
      changeYear: true,
      changeMonth: true,
      autoclose: true,
      maxDate: '+2y',
      onClose: function(selectedDate) {
        if (this.id == 'start_date') {
          $('#end_date').datepicker('option', 'minDate', selectedDate);

          newMax = $(this).datepicker('getDate')
          if (!newMax) {
            return;
          }
          newMax = new Date(newMax.getFullYear(), newMax.getMonth(), newMax.getDate() + 60)
          $('#end_date').datepicker('option', 'maxDate', newMax);
        }
        if (this.id == 'end_date') {
          $('#start_date').datepicker('option', 'maxDate', selectedDate);

          newMin = $(this).datepicker('getDate')
          if (!newMin) {
            return;
          }
          newMin = new Date(newMin.getFullYear(), newMin.getMonth(), newMin.getDate() - 60)
          $('#start_date').datepicker('option', 'minDate', newMin);
        }
      }
    }).on("change", function(e) {
      this.onDateChange($(e.target).data('field'), e);
    }.bind(this));
  }

  ajaxPtoBalance = (start_date) => {
    if (!start_date.isValid()) {
      start_date = moment();
    }
    $.ajax({
      url: this.state.url + '/' + start_date.format('YYYY-MM-DD') + '.json',
      dataType: 'json',
      success: function(data) {
        this.setState({
          pto_balance: data.pto_balance,
          balance_date: start_date.format('MM/DD/YYYY'),
          pto_unlimited: data.pto_unlimited
        });
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
      }.bind(this)
    });
  }

  setCurrentUser = (current_user) => {
    this.setState({
      selected_user: current_user,
      url: '/users/' + current_user + '/pto_balance'
    }, function() {
      this.ajaxPtoBalance(moment(this.state.start_date, 'YYYY-MM-DD'))
    });
  }

  onDayAmountChange = (i, e) => {
    var pto_details = this.state.pto_details.slice();
    pto_details[i]['amount'] = parseFloat(e.target.value);
    this.setState({
      pto_details: pto_details
    });
  }

  onDateChange = (date_type, e) => {
    var date_values = {};
    date_values['start_date'] = this.state.start_date;
    date_values['end_date'] = this.state.end_date;
    // Update whichever date was altered
    date_values[date_type] = e.target.value;

    var start_date = moment(date_values['start_date'], 'YYYY-MM-DD');
    var end_date = moment(date_values['end_date'], 'YYYY-MM-DD');

    var start_day_invalid = false;
    var end_day_invalid = false;
    var errors = [];
    var pto_details = [];
    var save_disabled;

    if (!errors.length && start_date > end_date) {
      errors.push('Start date needs to be before the end date.');
      start_day_invalid = true;
      save_disabled = true;
    }
    if (start_date.isValid() && end_date.isValid()) {
      var start_date_plus_two_months = start_date.clone();
      start_date_plus_two_months.add(2, 'months');

      if (end_date.isAfter(start_date_plus_two_months)) {
        errors.push("You're requesting more than two months of time off.");
        save_disabled = true;
      }
    }
    if (!errors.length && start_date.isValid() && end_date.isValid()) {
      this.ajaxPtoBalance(start_date);

      save_disabled = false;
      duration_days = end_date.diff(start_date, 'days');

      for (var i = 0; i <= duration_days; i++) {
        new_date = start_date.clone();
        new_date.add(i, 'days');
        if (new_date.day() == 0 || new_date.day() == 6) {
          continue;
        }
        pto_details.push({day: new_date.format('YYYY-MM-DD'), amount: 1});
      }
    }
    this.setState({
      start_date: date_values['start_date'],
      end_date: date_values['end_date'],
      errors: errors,
      save_disabled: save_disabled,
      start_day_invalid: start_day_invalid,
      end_day_invalid: end_day_invalid,
      pto_details: pto_details
    });
  }

  render() {
    var start_day_classes = classNames('required field', {error: this.state.start_day_invalid});
    var end_day_classes = classNames('required field', {error: this.state.end_day_invalid});
    var save_class = classNames({disabled: this.state.save_disabled});
    var pto_amount_total = 0;

    if (this.state.pto_details.length) {
      pto_amount_total = this.state.pto_details.reduce(function(prev, curr) {
        return parseFloat(prev) + parseFloat(curr.amount);
      }, 0);
    }

    var days_fields = [];
    for (var i = 0; i < this.state.pto_details.length; i++) {
      days_fields.push(
        <div key={i} className='row'>
          <div className='eight wide column'>
            <label>{moment(this.state.pto_details[i]['day'], 'YYYY-MM-DD').format('dddd MM/DD/YYYY')}</label>
            <input type='hidden' name='pto_request[pto_details][][day]' value={this.state.pto_details[i]['day']} />
            <input type='hidden' name='pto_request[pto_details][][amount]' value={this.state.pto_details[i]['amount']} />
          </div>
          <div className='eight wide column'>
            <div className='inline fields'>
              <div className='field'>
                <div className="ui radio checkbox">
                  <input
                    type="radio"
                    id={'half_day_' + i}
                    name={i + '_pto_request[pto_details][][amount]'}
                    defaultValue='0.5'
                    checked={this.state.pto_details[i]['amount'] == 0.5}
                    onChange={this.onDayAmountChange.bind(null, i)} />
                  <label htmlFor={'half_day_' + i}>Half Day</label>
                </div>
              </div>
              <div className='field'>
                <div className="ui radio checkbox">
                  <input
                    type="radio"
                    id={'full_day_' + i}
                    name={i + '_pto_request[pto_details][][amount]'}
                    defaultValue='1'
                    defaultChecked='true'
                    checked={this.state.pto_details[i]['amount'] == 1}
                    onChange={this.onDayAmountChange.bind(null, i)} />
                  <label htmlFor={'full_day_' + i}>Full Day</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (days_fields.length) {
      var days_fields_wrapper = <div className='ui grid'>
        {days_fields}
      </div>
    }
    else {
      var days_fields_wrapper = '';
    }
    var userDropdown = this.props.users.length > 1 ? <UserDropdown is_new={this.props.is_new} setCurrentUser={this.setCurrentUser} users={this.props.users} selected_user={this.props.selected_user} /> : '';

    return (
      <div className='ui form'>
        {userDropdown}
        <div className='two fields'>
          <DatepickerField
            classes={start_day_classes}
            label='Start Date'
            name='pto_request[start_date]'
            value={this.state.start_date}
            onChange={this.onDateChange.bind(null, 'start_date')}
            field_type='start_date' />
          <DatepickerField
            classes={end_day_classes}
            label='End Date'
            name='pto_request[end_date]'
            value={this.state.end_date}
            onChange={this.onDateChange.bind(null, 'end_date')}
            field_type='end_date' />
        </div>
        <ErrorMessages errors={this.state.errors} />
        {
          this.state.pto_unlimited ? null :
            <h4 className="ui horizontal header green divider">
              <span><i className="ui star icon"></i></span>
              {formatters.truncateDays(this.state.pto_balance)} days of PTO available on {this.state.balance_date}
            </h4>
        }
        {days_fields_wrapper}
        <h4 className="ui horizontal header blue divider">
          <span><i className="ui wait icon"></i></span>
          {pto_amount_total} days
        </h4>
        <input type='hidden' name='pto_request[amount]' value={pto_amount_total} />
        <input type='submit' className={'ui primary button ' + save_class} value='Save' name='commit' />
        <a href='javascript:history.back()' className='ui button red'>Cancel</a>
      </div>
    );
  }
}

export default PtoRequestApp;
