import React from 'react'
import PropTypes from "prop-types"
import {classNames} from '../utils/classnames'

class UserDropdown extends React.Component {
  static propTypes = {
    is_new:           PropTypes.bool.isRequired,
    selected_user:    PropTypes.number.isRequired,
    users:            PropTypes.array.isRequired,
    setCurrentUser:   PropTypes.func.isRequired
  }

  componentDidMount() {
    $('.user-dropdown').dropdown();
  }

  handleChange =(e) => {
    this.props.setCurrentUser(e.target.value);
  }

  render() {
    var dropdown_classes = classNames('ui dropdown user-dropdown', {disabled: !this.props.is_new});

    var createOption = function(user) {
      return <option key={user.id} value={user.id}>{user.name}</option>
    };

    return (
      <div className="field">
        <label>User</label>
        <select
          className={dropdown_classes}
          id="pto_request_user_id"
          name="pto_request[user_id]"
          onChange={this.handleChange}
          defaultValue={this.props.selected_user}
        >
          {this.props.users.map(createOption)}
        </select>
      </div>
    )
  }
}

export default UserDropdown;
