import React from 'react'
import PropTypes from 'prop-types'

class PtoComment extends React.Component {
  render() {
    if (this.props.comment == null) {
      return null;
    }

    var own_comment = this.props.current_user_id == this.props.comment.user_id;

    var actions = !own_comment ? null :
      <div className="actions">
        <a onClick={this.props.deleteComment}>
          <i className="trash icon"></i> Delete
        </a>
      </div>;

    return (
      <div className="comment">
        <div className={(this.props.loading == this.props.comment.id ? 'loading ' : '') + (own_comment ? 'ui segment' : '') + ' content'}>
          <span className="author">{this.props.comment.user_name}</span>
          <div className="metadata">
            <span className="date">{moment(this.props.comment.created_at).fromNow()}</span>
          </div>
          <div className="text">
            {this.props.comment.note}
          </div>
          {actions}
        </div>
      </div>
    )
  }
}

export default PtoComment;
