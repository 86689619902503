import React from 'react'
import PropTypes from 'prop-types'

class Dropdown extends React.Component {
  static propTypes = {
    field_id:         PropTypes.string,
    field_name:       PropTypes.string.isRequired,
    field_label:      PropTypes.string.isRequired,
    options:          PropTypes.any,
    selected_option:  PropTypes.number,
    changeCallback:   PropTypes.func,
    disabled:         PropTypes.bool
  }

  constructor(props) {
    super(props);
    this.state = {
      options: JSON.parse(props.options),
      disabled: props.disabled ? ' disabled' : ''
    };
  }

  componentDidMount() {
    $('#' + this.props.field_id).dropdown();
    $('#clear_' + this.props.field_id).click({field_id: this.props.field_id}, function(e) {
      $('.dropdown.ui.' + e.data.field_id).dropdown('clear');
    });
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.options != this.props.options) {
      this.setState({
        options: nextProps.options
      });
      $('.dropdown.ui.' + this.props.field_id).dropdown('clear');
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    if (typeof this.props.changeCallback === 'function' && e.target.value) {
      this.props.changeCallback(e.target.value);
    }
  }

  render() {
    var createOption = function(option) {
      return <option key={option.id} value={option.id}>{option.name}</option>
    };

    return (
      <div className="field">
        <label htmlFor={this.props.field_id}>
          {this.props.field_label}
        </label>
        <select
          defaultValue={this.props.selected_option}
          className={this.props.field_id + ' ui dropdown' + this.state.disabled}
          id={this.props.field_id}
          name={this.props.field_name}
          onChange={this.handleChange} >
            <option key='0' value=''>{this.props.field_label}</option>
            {this.state.options.map(createOption)}
        </select>
      </div>
    )
  }
}

export default Dropdown;
