import React from 'react'
import PropTypes from 'prop-types'
import createReactClass from 'create-react-class'

var CalendarEvents = createReactClass({
  propTypes: {
    events:                    PropTypes.array,
    colors:                    PropTypes.array
  },

  render: function() {
    if (!this.props.events.length) {
      return null;
    }

    var events = [];
    for (var i = 0; i < this.props.events.length; i++) {
      var caption = this.props.events[i].url ?
        <a href={this.props.events[i].url}>
          {this.props.events[i].title}
        </a> :
        this.props.events[i].title;

      events.push(
        <div
          style={{'backgroundColor': this.props.colors[this.props.titles.indexOf(this.props.events[i].title)%this.props.colors.length]}}
          key={i}
          className='event'
          data-html={this.props.events[i].content}>
            {caption}
        </div>
      )
    }
    return (
      <div className='events'>
        {events}
      </div>
    )
  }
})

export default CalendarEvents;
