import React from 'react'
import PropTypes from 'prop-types'
import DeleteButton from '../shared/delete_button'

class PolicyAccrualDaysFields extends React.Component {
  static propTypes = {
    accrual_days:           PropTypes.array.isRequired,
    field_name:             PropTypes.string.isRequired
  }

  render() {
    var fields = [];
    for (var i = 0; i < this.props.accrual_days.length; i++) {
      var deleteButton = i > 0 ?
        <div className='three wide field'>
          <DeleteButton handleClick={this.props.RemoveClick.bind(null, i)} />
        </div> : '';

      fields.push(
        <div key={i} className='two spaced fields'>
          <div className='four wide field'>
            <input
              type="number"
              min="1"
              max="28"
              data-validate={this.props.field_name + '_accrual_days'}
              name={this.props.field_name + '[accrual_days][]'} value={this.props.accrual_days[i]}
              onChange={this.props.onDayChange.bind(null, i)}
            />
          </div>
          {deleteButton}
        </div>
      )
    }
    return <div>{fields}</div>
  }
}

export default PolicyAccrualDaysFields;
