import React from 'react'
import PropTypes from 'prop-types'
import DeleteButton from '../shared/delete_button'

class AccrualList extends React.Component {
  static propTypes = {
    items:                  PropTypes.array.isRequired,
    field_name:             PropTypes.string.isRequired,
    width:                  PropTypes.string,
    handleRemoveClick:      PropTypes.func.isRequired,
    handleAmountChange:     PropTypes.func.isRequired
  }

  getOrdinal = (n) => {
    var s=["th","st","nd","rd"], v=n%100;
    return n+(s[(v-20)%10]||s[v]||s[0]);
  }

  render() {
    var fields = [];
    for (var i = 0; i < this.props.items.length; i++) {
      var deleteButton = i > 0 ?
        <DeleteButton handleClick={this.props.handleRemoveClick.bind(null, i)} /> : '';

      var range = this.props.items[i];

      fields.push(
        <div className='spaced fields' key={i}>
          <div className={this.props.width + ' wide inline field'}>
            <div className='ui right labeled input'>
              <div className='ui grey label'>
                {this.getOrdinal(i + 1)} year {i + 1 == this.props.items.length ? ' and beyond' : ''}
              </div>
              <input
                type='number'
                min='0'
                step='0.5'
                name={this.props.field_name}
                value={range.amount}
                onChange={this.props.handleAmountChange.bind(null, i)} />
              <div className='ui label'>days</div>
            </div>
          </div>
          <div className='two wide field'>
            {deleteButton}
          </div>
        </div>
      );
    }
    return  <div>
              {fields}
            </div>;
  }
}

export default AccrualList;
