import React from 'react'
import PropTypes from 'prop-types'

class DatepickerField extends React.Component {
  static propTypes = {
    classes:        PropTypes.string,
    label:          PropTypes.string.isRequired,
    name:           PropTypes.string.isRequired,
    value:          PropTypes.string,
    onChange:       PropTypes.func.isRequired,
    field_type:     PropTypes.string.isRequired
  }

  render() {
    return (
      <div className={this.props.classes}>
        <label>{this.props.label}</label>
        <div className='ui left icon input'>
          <input
            type='text'
            name={this.props.name}
            className='datepicker'
            value={this.props.value}
            onChange={this.props.onChange}
            id={this.props.field_type}
            data-field={this.props.field_type} />
          <i className='ui icon calendar' />
        </div>
      </div>
    )
  }
}

export default DatepickerField;
